import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, retrieveList } from "../api"
import { Space } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [spaces, setSpaces] = useState([])
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const retrieveSpaces = async () => {
    setLoadingSpaces(true)
    await retrieveList(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Space", message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Space", t("The space has been created."))]))
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${response.data.id}`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Space", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      retrieveSpaces()
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Spaces"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/`
          },
          {
            label: t("Create"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/create/`
          }
        ])
      )
  }, [siteStore.id, organizationStore.id])

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>{t("Create space")}</h2>
        </div>

        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={Space(spaces, loadingSpaces)}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("Cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              disabled={processing || loadingSpaces}
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
