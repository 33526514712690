import { getAllValues, retrieveList } from "/apps/connectivity/connector-instance/api"
import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)
  const { gateway } = useContext(GatewayRoutesContext)
  const DEFAULT_FILTER = { gateway: [gateway.id] }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    // { field: "protocols", header: t("Protocols") },
    // { field: "description", header: t("Description") },
    // { field: "stores_response_timeout", header: "Response timeout" },
    { field: "number_stores", header: t("Number of stores"), sortable: true },
    { field: "number_points", header: t("Number of points"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name"), sortable: true },
    // { field: "protocols", header: t("Protocols") },
    // { field: "description", header: t("Description") },
    // { field: "stores_response_timeout", header: "Response timeout" },
    { field: "number_stores", header: t("Number of stores") },
    { field: "number_points", header: t("Number of points") }
  ]

  const retrieve = async (queryParams = { ...DEFAULT_FILTER, limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Connector instances", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(gateway.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const getDetailUrl = connectorId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/`

  const getDeleteUrl = connectorId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/delete/`

  const onRowClick = connectorId => {
    navigate(getDetailUrl(connectorId))
  }

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          }
        ])
      )
      getFilterValues()
    }
  }, [siteStore.id, gateway])

  const queryParams = () => {
    return {
      ...filters,
      ...DEFAULT_FILTER,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  return (
    siteStore.id && (
      <section className="list-section">
        <ListActions
          filterBackend={true}
          filters={menuFilters}
          loading={loadingFilters}
        >
          <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/create/`}>
            <Button
              rounded
              severity="info"
              icon="fa-regular fa-plus"
              label={t("Create")}
            />
          </Link>
        </ListActions>

        <ListLayout
          columns={columns}
          value={items}
          objectName={t("Connector instances")}
          loading={loading}
          filters={filters}
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
          bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
          handleSelection={setSelected}
          limit={limit}
          offset={offset}
          setLimit={setLimit}
          setOffset={setOffset}
          count={count}
          onSort={onSort}
          sortOrder={sortOrder}
          sortField={sortField}
        />
      </section>
    )
  )
}
