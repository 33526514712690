import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const { id } = useParams()

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Edge block templates"), url: "/configuration/edge-block-templates/" },
        { label: t("Detail"), url: `/configuration/edge-block-templates/${id}/` },
        { label: t("Delete"), url: `/configuration/edge-block-templates/${id}/delete/` }
      ])
    )
  }, [])

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const removeObject = async () => {
    await remove(id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The edge block template has been removed"))])
        navigate("/configuration/edge-block-templates/")
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  return (
    <DeleteConfirmation
      objectType="Edge block template"
      remove={removeObject}
      goBack={() => navigate(-1)}
    />
  )
}
