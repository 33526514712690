import { BoxContentRoutesContext } from "/apps/configuration/gateway/box/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["configuration"])
  const [relatedObjects, setRelatedObjects] = useState(null)
  const [loadingRelatedObjects, setLoadingRelatedObjects] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [gatewaysCount, setGatewaysCount] = useState(0)
  const { box } = useContext(BoxContentRoutesContext)

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const count = async () => {
    setLoadingRelatedObjects(true)
    await getCount(box.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
        setGatewaysCount(response.data.count.gateways_count)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Gateway Box", message)])
      })
      .finally(() => setLoadingRelatedObjects(false))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(box.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Gateway Box", t("The gateway box has been removed."))])
        navigate("/configuration/gateways/boxes/")
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Gateway Box", message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (box.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Gateways"), url: "/configuration/gateways/boxes/" },
          { label: t("Boxes"), url: "/configuration/gateways/boxes/" },
          { label: t("Detail"), url: `/configuration/gateways/boxes/${box.id}/` },
          { label: t("Delete"), url: `/configuration/gateways/boxes/${box.id}/delete/` }
        ])
      )

      count()
    }
  }, [box.id])

  return (
    <DeleteConfirmation
      objectType={t("Gateway box")}
      remove={removeObject}
      relatedObjects={relatedObjects}
      goBack={() => navigate(-1)}
      forbidden={gatewaysCount > 0}
      loadingRelatedObject={loadingRelatedObjects}
      processing={processing}
      message={t("Delete gateway box link to a site is not allowed.")}
    />
  )
}
