import { TransmissionRoutesContext } from "/apps/configuration/energy-company/transmission/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { update } from "../api"
import { TSO } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const { t } = useTranslation(["configuration"])
  const auth = useAuth()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const { transmission, retrieveDetail } = useContext(TransmissionRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(transmission.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The TSO has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (transmission) {
      setLoading(false)
      setData(transmission)

      transmission.id &&
        dispatch(
          updateBreadcrumb([
            { label: t("Energy companies"), url: "/configuration/energy-company/distributors/" },
            { label: t("TSO"), url: "/configuration/energy-company/transmissions/" },
            { label: transmission.name, url: `/configuration/energy-company/transmissions/${transmission.id}/` }
          ])
        )
    }
  }, [transmission])

  return (
    transmission && (
      <BaseLayout title={transmission.name}>
        <OverView
          object={transmission}
          model={TSO()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          loading={loading}
          processing={processing}
          deleteUrl={`/configuration/energy-company/transmissions/${transmission.id}/delete/`}
        />
      </BaseLayout>
    )
  )
}
