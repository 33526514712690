import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { Organization } from "../models"
import LogoInput from "./LogoInput"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["organization"])
  const [data, setData] = useState({
    logo: "",
    address: "",
    active: true
  })
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Create", t("The organization has been created."))]))
        navigate("/")
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: "Create", url: "/organizations/create/" }]))
  }, [])

  return (
    data && (
      <BaseLayout title={t("Create organization")}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={Organization()}
            data={data}
            setData={setData}
            errors={errors}
          />

          <LogoInput
            data={data}
            setData={setData}
          />

          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("Cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
              disabled={processing}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={processing}
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
