import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Cards } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"

function ConnectivityHome() {
  const siteStore = useSelector(site)
  const { t } = useTranslation(["assets"])
  const dispatch = useDispatch()

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` }
        ])
      )
    }
  }, [siteStore.id])

  return (
    <Cards
      items={[
        {
          icon: "fa-solid fa-gear",
          url: `/sites/${siteStore.id}/connectivity/connectivity-view`,
          title: t("Connectivity view")
        }
      ]}
    />
  )
}

export default function ConnectivityRoutes() {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={<ConnectivityHome />}
      />
    </Routes>
  )
}
