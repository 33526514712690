import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { retrieveList, update } from "../api"
import { Space, SpaceOverview } from "../models"
import { SpaceRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [spaces, setSpaces] = useState([])
  const { space, retrieveDetail } = useContext(SpaceRouteContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)

    await update(siteStore.id, space.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The space has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const retrieveSpaces = async () => {
    await retrieveList(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const deleteUrl = () => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${space.id}/delete/`

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    space && setLoading(false)
  }, [space])

  useEffect(() => {
    if (organizationStore.id && siteStore.id && space && space.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Equipments"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/`
          },
          {
            label: space.label ?? space.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${space.id}/`
          }
        ])
      )

      retrieveSpaces()
    }
  }, [siteStore.id, organizationStore.id, space])

  return (
    space && (
      <>
        <div className="base-content-title">
          <h2>{space.name}</h2>
        </div>

        <OverView
          object={space}
          model={Space(spaces)}
          overviewModel={SpaceOverview()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          processing={processing}
          setEdit={setEdit}
          deleteUrl={deleteUrl()}
        />
      </>
    )
  )
}
