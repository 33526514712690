import { TransmissionRoutesContext } from "/apps/configuration/energy-company/transmission/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const { t } = useTranslation(["configuration"])
  const { transmission } = useContext(TransmissionRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(transmission.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The TSO has been removed."))])
        navigate("/configuration/energy-company/transmissions/")
      })
      .catch(error => {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Error", message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    transmission &&
      transmission.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Energy companies"), url: "/configuration/energy-company/distributors/" },
          { label: t("TSO"), url: "/configuration/energy-company/transmissions/" },
          { label: transmission.name, url: `/configuration/energy-company/transmissions/${transmission.id}/` },
          { label: t("Delete"), url: `/configuration/energy-company/transmissions/${transmission.id}/delete/` }
        ])
      )
  }, [transmission])

  return (
    transmission && (
      <DeleteConfirmation
        objectType={t("TSO")}
        objectNames={[transmission.name]}
        remove={removeObject}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}
