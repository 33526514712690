import { BoxContentRoutesContext } from "/apps/configuration/gateway/box/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { update } from "../api"
import { GatewayBox } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const { box, retrieveDetail } = useContext(BoxContentRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(box.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The gateway box has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data.message && setErrors(error.response.data.message)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    box.id && setLoading(false)

    box &&
      box.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Gateways"), url: "/configuration/gateways/boxes/" },
          { label: t("Boxes"), url: "/configuration/gateways/boxes/" },
          { label: box.label, url: `/configuration/gateways/boxes/${box.id}/` }
        ])
      )
  }, [box])

  return (
    box && (
      <BaseLayout title={box.label}>
        <OverView
          object={box}
          model={GatewayBox()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          loading={loading}
          processing={processing}
          deleteUrl={`/configuration/gateways/boxes/${box.id}/delete/`}
        />
      </BaseLayout>
    )
  )
}
