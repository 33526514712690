import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"

import { BaseLayout, Loader, OverView, ReturnButton } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useNavigate, useParams } from "react-router-dom"

import { retrieve, update } from "../api"
import { EdgeBlockTemplate } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const navigate = useNavigate()
  const [object, setObject] = useState(null)
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
        setLoading(false)
      })
      .catch(error => {
        dispatch(addNotifications(errorNotification("Error", error.message)))
      })
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Edge block templates"), url: "/configuration/edge-block-templates/" },
        { label: t("Detail"), url: `/configuration/edge-block-templates/${id}/` }
      ])
    )

    retrieveDetail()
  }, [id])

  const updateObject = async function (e) {
    e.preventDefault()
    await update(id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The edge block template has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return object ? (
    <>
      <ReturnButton
        label={t("Back to edge block template list")}
        onClick={() => navigate("/optimization/edge-block-templates")}
      />
      <BaseLayout title={`${t("Edge block template")} ${object.name}`}>
        <OverView
          object={object}
          model={EdgeBlockTemplate()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          setEdit={setEdit}
          deleteUrl={`/configuration/edge-block-templates/${id}/delete/`}
        />
      </BaseLayout>
    </>
  ) : (
    <Loader />
  )
}
