import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { Contract } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [energyDeliveryPoints, setEnergyDeliveryPoints] = useState([])
  const [energyCompanies, setEnergyCompanies] = useState([])
  const [gateways, setGateways] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingEnergyDeliveryPoints, setLoadingEnergyDeliveryPoints] = useState(false)
  const [loadingEnergyCompanies, setLoadingEnergyCompanies] = useState(false)
  const [loadingGateways, setLoadingGateways] = useState(false)

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Energy contract", t("The contract has been created."))]))
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/${response.data.id}/`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Energy contract", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getEDP = async () => {
    setLoadingEnergyDeliveryPoints(true)
    await paginatedRequest(`energy/sites/${siteStore.id}/energy_delivery_points/`, auth.userData.access_token)()
      .then(results => {
        setEnergyDeliveryPoints(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Energy delivery points", error.message)]))
      })
      .finally(() => setLoadingEnergyDeliveryPoints(false))
  }

  const getEC = async () => {
    setLoadingEnergyCompanies(true)
    await paginatedRequest("energy/energy_companies/", auth.userData.access_token)()
      .then(results => {
        setEnergyCompanies(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Energy companies", error.message)]))
      })
      .finally(() => setLoadingEnergyCompanies(false))
  }

  const getGW = async () => {
    setLoadingGateways(true)
    await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
      .then(results => {
        setGateways(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Gateways", error.message)]))
      })
      .finally(() => setLoadingGateways(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Contracts"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/`
          },
          {
            label: t("Create"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/create/`
          }
        ])
      )

      getEDP()
      getEC()
      getGW()
    }
  }, [siteStore.id, organizationStore.id])

  return (
    <>
      <div className="base-content-title">
        <h2>{t("Create contract")}</h2>
      </div>

      <form
        className="form-view"
        onSubmit={create}
      >
        <FormContent
          model={Contract(energyDeliveryPoints, energyCompanies, gateways, loadingEnergyDeliveryPoints, loadingEnergyCompanies, loadingGateways)}
          data={data}
          setData={setData}
          errors={errors}
        />
        <div className="action-buttons-wrapper">
          <Button
            rounded
            outlined
            severity="secondary"
            label={t("Cancel")}
            disabled={processing || loadingEnergyDeliveryPoints || loadingEnergyCompanies || loadingGateways}
            icon="fa-solid fa-xmark"
            onClick={goBack}
          />
          <ButtonWithLoader
            rounded
            severity="success"
            label={t("Save")}
            disabled={processing || loadingEnergyDeliveryPoints || loadingEnergyCompanies || loadingGateways}
            type="submit"
            icon="fa-solid fa-floppy-disk"
          />
        </div>
      </form>
    </>
  )
}
