import { ConnectorInstanceRoutesContext } from "/apps/connectivity/connector-instance/routes"
import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { siteId, gatewayId, connectorInstanceId } = useParams()
  const [relatedObjects, setRelatedObjects] = useState(null)
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    setLoadingRelatedObject(true)
    await getCount(connectorInstanceId, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Connector instance", message)])
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const removeObject = async () => {
    await remove(siteId, connectorInstanceId, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Connector instance", t("The connector instance has been removed."))])
        navigate(`/organizations/${organizationStore.id}/sites/${siteId}/assets/gateways/${gatewayId}/connector-instances`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Connector instance", message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && connector) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          },
          {
            label: connector.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/delete/`
          }
        ])
      )

      count()
    }
  }, [siteStore.id, gateway, connector])

  return (
    connector && (
      <DeleteConfirmation
        objectType={t("Connector instance")}
        objectNames={[connector.name]}
        remove={removeObject}
        relatedObjects={relatedObjects}
        loadingRelatedObject={loadingRelatedObject}
        goBack={() => navigate(-1)}
      />
    )
  )
}
