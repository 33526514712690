import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SpaceConstraintRouteContext } from "../../space-constraint/routes"
import { add } from "../api"
import { SpaceConstraintDetail } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { spaceConstraint } = useContext(SpaceConstraintRouteContext)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(siteStore.id, spaceConstraint.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Space constraint detail", t("The space constraint detail has been created."))]))
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/${response.data.id}`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Space constraint detail", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    if (siteStore && siteStore.id && spaceConstraint && spaceConstraint.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Space constraints"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/`
          },
          {
            label: spaceConstraint.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/`
          },
          {
            label: t("Details"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details`
          },
          {
            label: t("Create"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/space-constraint-details/create/`
          }
        ])
      )
    }
  }, [siteStore.id, spaceConstraint.id, organizationStore.id])

  return (
    siteStore.id &&
    spaceConstraint &&
    spaceConstraint.id && (
      <form
        className="form-view"
        onSubmit={create}
      >
        <FormContent
          model={SpaceConstraintDetail()}
          data={data}
          setData={setData}
          errors={errors}
        />
        <div className="action-buttons-wrapper">
          <Button
            rounded
            outlined
            severity="secondary"
            disabled={processing}
            label={t("Cancel")}
            icon="fa-solid fa-xmark"
            onClick={goBack}
          />
          <ButtonWithLoader
            rounded
            severity="success"
            disabled={processing}
            label={t("Save")}
            type="submit"
            icon="fa-solid fa-floppy-disk"
          />
        </div>
      </form>
    )
  )
}
