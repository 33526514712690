import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { BaseLayout, Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { retrieve, update } from "../api"
import { ProposedCurtailSchedule } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])

  const [object, setObject] = useState(null)
  const { siteId, flexId, scheduleId } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    await retrieve(flexId, scheduleId, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
        setData(response.data)
        setLoading(false)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const updateObject = async function (e) {
    e.preventDefault()
    await update(flexId, scheduleId, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The proposed curtail schedule has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        {
          label: t("Proposed curtail schedules"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/`
        },
        {
          label: t("Detail"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/${scheduleId}/`
        }
      ])
    )

    retrieveDetail()
  }, [scheduleId])

  return object ? (
    <BaseLayout title={`${t("Proposed curtail schedule")} ${object.name}`}>
      <OverView
        object={object}
        model={ProposedCurtailSchedule()}
        updateObject={updateObject}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        setEdit={setEdit}
        loading={loading}
        deleteUrl={`/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/${scheduleId}/delete/`}
      />
    </BaseLayout>
  ) : (
    <Loader />
  )
}
