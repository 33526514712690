import { DistributorRoutesContext } from "/apps/configuration/energy-company/distributor/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { update } from "../api"
import { Distributor as DistributorModel } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const { distributor, retrieveDetail } = useContext(DistributorRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(distributor.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The distributor has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (distributor) {
      setLoading(false)
      setData(distributor)

      distributor.id &&
        dispatch(
          updateBreadcrumb([
            { label: t("Energy companies"), url: "/configuration/energy-company/distributors/" },
            { label: t("Distributors"), url: "/configuration/energy-company/distributors/" },
            { label: distributor.name, url: `/configuration/energy-company/distributors/${distributor.id}/` }
          ])
        )
    }
  }, [distributor])

  return (
    distributor && (
      <BaseLayout title={distributor.name}>
        <OverView
          object={distributor}
          model={DistributorModel()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          loading={loading}
          processing={processing}
          deleteUrl={`/configuration/energy-company/distributors/${distributor.id}/delete/`}
        />
      </BaseLayout>
    )
  )
}
